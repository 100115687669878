
@keyframes bounceInLeft {
    0% {
        transform: translateX(200%);
        opacity: 0;
    }
    50% {
        transform: translateX(-30px);
        opacity: 1;
    }
    70% {
        transform: translateX(15px);
    }
    85% {
        transform: translateX(-8px);
    }
    100% {
        transform: translateX(0);
    }
}

.visitorCount {
    animation: bounceInLeft 1s ease-out;
    background-color: rgb(255, 201, 117);
    color: rgb(158, 27, 30);
    font-weight: bold;
    width: max-content;
    padding: 12px 24px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 9999;
    border-radius: 4px;
    min-width: 260px;
}

.vistorCount p {
    margin: 0;
    padding: 0;
}

.visitor-block {
    padding-left: 3px;
}

@keyframes flicker {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  
.flicker-text {
    animation: flicker 0.5s infinite 3s; /* Flickers every 5 seconds */
}