@media (max-width: 480px) {
    .d-flex.mobile-width {
      width: 100%!important;
      
    }
    .d-flex.flex-column.flex-md-row {
        margin-top: 5vw!important;
    }
    .d-flex.flex-column.flex-md-row.test {
        margin-top: 0vw!important;
        margin-bottom: 5vw!important;
    }
  }

  .d-flex.mobile-width {
    width: 30rem;
  }
  .d-flex.flex-column.flex-md-row {
    margin-top: 0vw;
}

.d-flex.flex-column.flex-md-row.test {
    margin-top: 0vw;
    margin-bottom: 5vw;

}