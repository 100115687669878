.d-flex {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  @media (min-width: 768px) {
    .d-flex {
      flex-direction: row;
    }
    
    .izb {
      flex: 1;
    }
  }