h1.test.text-white {
    padding-top: 4rem;
}


@media (max-width: 480px) {
    h1.test.text-white {
        padding-top: 0rem;
        font-size: 6vw;
    }
}