.heading-box {
  background-color: #9e1b1e;
}
.Hborder {
  display: block;
  width: 100%; /*or whatever width you want the effect of <hr>*/
  border-top: 1px solid #ccc;
}
.CHeight {
  height: 400px;
}

.AccOpen {
  font-size: 54px;
}
.AfterText {
  display: none;
}
.HeroSlider1 {
  background-image: url("https://izbassetsmain.blob.core.windows.net/izbwebassets/Slider1.png");
  height: 100vh;
  object-fit: cover;
  object-position: bottom right;
  overflow: "hidden";
}
.HeroSlider2 {
  background-image: url("https://izbassetsmain.blob.core.windows.net/izbwebassets/web banner-04.jpg");
  height: 100%;
  object-fit: cover;
  object-position: bottom right;
  overflow: "hidden";
}
.HeroSlider3 {
  background-image: url("https://izbassetsmain.blob.core.windows.net/izbwebassets/Desktop_Bannernew.png");
  height: 100%;
  object-fit: cover;
  object-position: bottom right;
  overflow: "hidden";
}
.HeroSlider4 {
  background-image: url("https://izbassetsmain.blob.core.windows.net/izbwebassets/1banner.png");
  height: 100%;
  object-fit: cover;
  object-position: bottom right;
  overflow: "hidden";
}

.HeroSlider5 {
  background-image: url("https://izbassetsmain.blob.core.windows.net/izbwebassets/IZB_BANNER.png");
  height: 100%;
  object-fit: cover;
  object-position: bottom right;
  overflow: "hidden";
}

.HeroSlider6 {
  background-image: url("https://izbassetsmain.blob.core.windows.net/izbwebassets/IZB_BANNER_House_desktop.png");
  height: 100%;
  object-fit: cover;
  object-position: bottom right;
  overflow: "hidden";
}

.Spacer {
  height: 350px;
}
.SaveAccountSection {
  padding: 100px 0 100px 0;
}
.customerSection {
  padding: 50px 0 50px 0;
}
.interest-charges-area {
  margin-right: 70px;
  margin-left: 70px;
}
.designationStyle {
  width: 50%;
}
.SliderImg {
  top: -190px;
}
h6.p-2.mb-2.rounded.text-wrap {
  background-color: rgb(255, 201, 117);
  color: rgb(158, 27, 30);
  width: 350px;
}
img.slick-arrow.slick-next {
  display: block;
  width: 50px;
  height: 50px;
  right: -12px;
}
section.documents-area.col-sm-10.col-md-10.col-lg-10.col-xl-10.shadow.p-0.p-md-3.me-3  {
  position: absolute;
  border-radius: 12px;
  background-color: #FFF5E7;
  left: 8%;
  top: 75%;
}
.testt.row.align-items-center {
  --bs-gutter-x: -1.5rem;

  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x)/ -2);
  margin-left: calc(var(--bs-gutter-x)/ -2);
}
@media screen and (max-width: 480px) {
  
  h6.p-2.mb-2.rounded.text-wrap {
    background-color: rgb(255, 201, 117);
    color: rgb(158, 27, 30);
    width: 320px;
  }
  img.slick-arrow.slick-prev {
    display: block;
    width: 50px;
    height: 50px;
    left: 0px !important;
    z-index: 99;
}

section.documents-area.col-sm-10.col-md-10.col-lg-10.col-xl-10.shadow.p-0.p-md-3.me-3 {
  position: absolute;
  border-radius: 12px;
  background-color: #FFF5E7;
  left: 4%;
  top: 75%;
}
img.slick-arrow.slick-next {
  display: block;
  width: 50px;
  height: 50px;
  right: 0px !important;
}
.row.border.justify-content-sm-center.p-4.my-5 {
  text-align: center;
}
  .row {
    --bs-gutter-x: -0.5rem;

    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x)/ -2);
    margin-left: calc(var(--bs-gutter-x)/ -2);
}
.testt.row.align-items-center {
  --bs-gutter-x: -5.5rem;

  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x)/ -2);
  margin-left: calc(var(--bs-gutter-x)/ -2);
}
  .bannerPhone {
    display: none;
  }
  .AccOpen {
    font-size: "34px";
  }
  .HeroSlider {
    height: 50vh;
  }
  .postion-sm-static {
    position: static;
  }
  .SliderImg {
    top: "30%";
  }
  .SliderArrow {
    display: none;
  }
  .HeroSlider1 {
    background-image: url("https://izbassetsmain.blob.core.windows.net/izbwebassets/ITB Mobile Banner-01.jpg");
    width: 100%;
    height: 90vh !important;
  }
  .HeroSlider2 {
    background-image: url("https://izbassetsmain.blob.core.windows.net/izbwebassets/web banner-05.jpg");
    width: 100%;
    height: 90vh !important;
  }
  .HeroSlider3 {
    background-image: url("https://izbassetsmain.blob.core.windows.net/izbwebassets/newbanner.png");
    width: 100%;
    height: 90vh !important;
  }
  .HeroSlider4 {
    background-image: url("https://izbassetsmain.blob.core.windows.net/izbwebassets/3banner.jpg");
    width: 100%;
    height: 90vh !important;
  }

  .HeroSlider5 {
    background-image: url("https://izbassetsmain.blob.core.windows.net/izbwebassets/indo vehicle loan Mobile.png");
    width: 100%;
    height: 90vh !important;
  }

  .HeroSlider6 {
    background-image: url("https://izbassetsmain.blob.core.windows.net/izbwebassets/IZB_BANNER_House_mobile.png");
    width: 100%;
    height: 90vh !important;
  }

  .sliderContent {
    display: none;
  }
  .Spacer {
    height: 550px;
  }
  .BankingMenu {
    margin-top: 1.5rem;
  }
  .SaveAccountSection {
    padding: 0px;
    /* margin-bottom: 30px; */
  }
  .customerSection {
    padding: 0px;
    margin-bottom: 30px;
  }
  .interest-charges-area {
    margin: 0px;
  }
  .overview-area {
    padding: 0 0 0px;
  }

  .designationStyle {
    width: 100%;
  }
  .SliderImg {
    top: -55px;
  }
  .BankMenuLinks {
    font-size: 11px;
    text-align: left !important;
  }
  .MenuTitle{
    font-size: 15px;
  }
  .text.col-12.col-md-4.col-lg-4.col-xl-4.text-white.ps-1.mb-4.mt-2 {
    background-color: #C60000;
    font-family: inter;
    font-weight: 300;
    letter-spacing: 1.5px;
    font-size: 17px;
    text-align: center!important;

  }
  .h33{
    text-align: center!important;
  }
}
@media screen and (max-width: 480px) {


}
@media screen and (max-width: 375px) {
  img.slick-arrow.slick-prev {
    left: 0 !important;
  }
  img.slick-arrow.slick-next {
    right: 0 !important;
  }
}

.text.col-12.col-md-4.col-lg-4.col-xl-4.text-white.ps-1.mb-4.mt-2 {
  background-color: #C60000;
  font-family: inter;
  font-weight: 300;
  letter-spacing: 1.5px;
  font-size: 17px;
  text-align: left;
}
.h33{
  text-align: left;
}

img.my-3.mx-3.text-center{
  margin-left: 7rem!important;
  margin-right: 7rem!important;
}
#myButton {
  border: 1px solid rgb(158, 27, 30);
  background: rgb(158, 27, 30);
  color: white;
  border-radius: 15px;
  padding: 10px;
  font-weight: 600;
}

#myButton:hover {
  background: rgb(158, 27, 30);
}

#myButton.clicked {
  background: rgb(158, 27, 30);
}

#myButton.clicked:hover {
  background: rgb(158, 27, 30);
}
