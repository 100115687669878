/* Homepage.css */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    padding: 0px 12px;
    font-size: 2vw;
    font-weight: 600;
    cursor: pointer;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    width: 36rem;
    padding: 42px;
    margin: 0 auto;
}
.thejas{
    width: 2rem;
}
@media (max-width: 480px) {
    .ReactModal__Content.ReactModal__Content--after-open {
        width: 16.5rem;
        height: 26rem;
        padding: 42px;
        margin: 0 auto;
    }
    .thejas{
        width: 1rem;
    }
}

