.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.faq-section{
  padding-bottom: 50px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
 }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.text-justify {
  text-align: justify;
}
.executive-director-sec .container:first-child img,
.board-of-directors .container:first-child > .row:last-child img,
.board-of-directors .container:nth-child(3) img {
  height: 100%;
  object-fit: cover;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.image-row-report img{
  max-width:50%;
  margin:0 auto;
}

.latest-news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.news-slider-list-slider .slick-slide {
  padding-right: 16px;
}

.single-blog-style1 .text-holder .blog-title  {
  text-align: left;
}

.text-left {
  text-align: left;
}

.news-slider-main {
  margin-top: 60px;
  margin-bottom: 60px;
}

.show-on-tablet {
  display: none;
}

.read-more-flot-btn {
  float: right;
}
.single-blog-style1 .img-holder img{
 min-height: 232px;
}
@media only screen and (max-width:1140px) {
  .news-slider-main {
    margin-top: 150px;
    margin-bottom: 60px;
  }
  .single-blog-style1 .img-holder img{
    height: auto;
    }
}

@media only screen and (max-width:991px) {
  .hide-on-tablet {
    display: none;
  }

  .show-on-tablet {
    display: block;
  }

  .news-slider-main {
    margin-top: 360px;
  }

  .news-slider-main .news-slider-list-slider .slick-slide,
  .news-slider-main .single-blog-style1,
  .news-slider-sub .news-slider-list-slider .slick-slide,
  .news-slider-sub .single-blog-style1 {
    padding-right: 0px;
  }

  .news-slider-main .slick-arrow.slick-prev,
  .news-slider-sub .slick-arrow.slick-prev {
    left: -25px !important;
    position: absolute !important;
  }

  .read-more-flot-btn {
    float: unset;
    text-align: center;
  }

  .news-slider-sub {
    margin-bottom: 60px;
  }
  .single-blog-style1 .img-holder img{
    height: auto;
    }
}



@media only screen and (max-width:767px) {
  .image-row-report img {
     max-width:100%;
   } 

   .video-section {
    overflow: hidden !important;
    padding: 0 auto;
   }

  .executive-director-sec .container:first-child img,
  .board-of-directors .container:first-child > .row:last-child img,
  .board-of-directors .container:nth-child(3) img {
    height: auto !important;
    object-fit: contain !important;
  }
  .single-blog-style1 .img-holder img{
   height: auto;
   }

  .news-slider-main {
    margin-top: 240px;
    margin-bottom: -70px;
    overflow: hidden;
  }

  .news-slider-sub {
    margin-bottom: 0px;
  }

  .custom-flex {
    gap: 0 !important;
  }
 
}

