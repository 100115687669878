.thankyou{
  text-align: center; 
  margin: 2rem 1rem 2rem 1rem;
}
.thankyou1{
  width: 45%;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.thankyou123{
  width: 50%;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

@media (max-width: 480px) {
  .thankyou{
    text-align: center; 
    margin: 2rem 1rem 2rem 1rem;
  }
  .thankyou1{
    width: 90%;
  }
  .thankyou123{
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    
  }
}