.mobile-icons {
    position: fixed;
    bottom: 10px;
    right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999999999999;
  }
  
  .mobile-icons .icon {
    font-size: 30px;
    color: #000;
    margin: 5px;
    text-decoration: none;
    border: 1px solid rgb(158, 27, 30);
    padding: 4vw;
    border-radius: 50vw;
    color: white;
    background-color: rgb(158, 27, 30);
  }
  .mobile-icons .iconz {
    font-size: 30px;
    color: #000;
    margin: 5px;
    text-decoration: none;
    border: 1px solid #25D366;
    padding: 4vw;
    border-radius: 50vw;
    color: white;
    background-color: #25D366;
  }
  
  .mobile-icons .icon.whatsapp {
    border-color: #25D366;
    background-color: #25D366;
  }
  
  @media screen and (min-width: 769px) {
    .mobile-icons {
      display: none !important;
    }
  }
  .phone-options {
    position: absolute;
    top: -70%; /* Move the options above the icon */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .phone-options .option {
    font-size: 18px;
    /* color: #000; */
    margin: 5px;
    text-decoration: none;
    border: 1px solid rgb(158, 27, 30);
    padding: 1vw;
    width: 22vw;
    background-color: rgb(158, 27, 30);
    color: white;
    text-align: center;
  }
  

  @media screen and (max-width: 767px) {
    .footer-support {
      align-items: flex-start !important;
    }
}